<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Learn how  {{ tenant.name}} can boost your next hybrid event or challenge!</h1>
      <p>
        Explore the features  {{ tenant.name}} has to offer to make your virtual/hybrid event or challenge a success.
      </p>
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/feature-map.png?w=1080" alt="Create engaging virtual events and challenges." /> 
    </div>
    <div id="page-customers" class="mt-0 white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by world-class event organizers</h1>
            <p>
              The  {{ tenant.name}} Platform is used by world-renowned event organizers and managements organizations all across the world. Some of our key partners are:
            </p>
            <SectionCustomers showcase/>

          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
      <p>
        We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
        Our manual entry tool allows quick entry for everyone without a connected GPS device.
      </p>
      <SectionIntegrations />
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Go virtual or hybrid to increase your reach</h2>
            <h1 class="subtitle">Use  {{ tenant.name}} to create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Request Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Discover our key features</h1>
      <p>
      </p>
    </div>
    <v-container class="features">
      <v-row>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <h4>Auto-sync with Garmin, Fitbit, Polar and more</h4>
          <p>
            Activities tracked with Garmin, Polar, Suunto, Fitbit or COROS devices and the MapMyRun app are automatically synced and added to results making it extremely easy for your participants to make every km/mile count!
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Best result, total distance, total elevation and more</h4>
          <p>
            We support all popular challenge modes: best result, total distance or total elevation. Even better: you can combine them in the same virtual challenge!
          </p>
          <p> 
            Whether your virtual event/challenge runs for a day, a week or even multiple months, whether it is a single sport or a multi-sport event, you can host it on  {{ tenant.name}}.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>In-person race results</h4>
          <p>
            Easily upload the official race results from an in-person race. Whether your event is in-person only or combines an in-person race with a do-anywhere challenge, we can support your event and allow you to publish all results at one location. 
          </p>
          <p>
            Use our app to send push-notifications to participants and post photos or videos to the feed in our free app to give your participants the best event experience.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-mobile</v-icon>
          <h4>App</h4>
          <p>
            Use our free app to publish your results and share the latest updates with your participants. The app is fully customizable and allows you to upload sponsor logos and customize the design and layout for your event.
          </p>
          <p>Our app has an integrated step and activity tracker, allows manual entry, and also allows participants to connect their favorite fitness app or watch. This allows everyone to join your event, regardless of the tech they use!</p>
          <p>
            For larger events we can create customized event apps. Please reach out to us to learn more.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            We support all popular sports and you can combine them all in the same challenge.
          </p>
          <p> 
            Use our platform to host virtual triathlons, multi-sport events or any combinations of different activity types.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your virtual course map and we'll map the progress on the map.
          </p>
          <p>
            We show all participants on the same map and have a powerful search option to find your own or team members' results easily!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals based on distance or elevation completed and award badges to keep your participants motivated to make it to the (virtual) finish line! Use our consecutive challenge to motivate your participants to keep going and our location based badges for virtual treasure hunts or embed local content into your challenge.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-file-certificate</v-icon>
          <h4>Virtual Bibs and Finisher Certificates</h4>
          <p>
            Virtual Bibs and Finisher Certificates are two more pieces of shareable content to further improve user engagement during each challenge. Both features are also available for in-person events.
          </p>
          <p>
            Both the Virtual Bib and Finisher Certificate can be fully customized with event and sponsor logos, fonts, custom fields, and colors to create unique design to fit with the event experience.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Collective progress &amp; Participation Mode</h4>
          <p>
            We're into this together, so let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or team races</h4>
          <p>
            Create both individual or team challenges, or combinations of both! With the team functionality any group can work together to reach their common goals and compete with the other registered teams for first place!
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design &amp; co-branding</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
          <p>
            Want to take it a step further? We offer a completely co-branded, white-labeled version of the platform which you can host on your own domain.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>API integrations</h4>
          <p> 
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. 
            We support iframe embedding for a no-code integration.
            We have Excel import/export options available to help you get started without development work needed.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Simple pricing structure</h4>
          <p> 
            <router-link to="/pricing">Simple and fair pricing</router-link> that scales with your event. No setup-fees or minimum costs.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <iframe src="https://cdn.forms-content.sg-form.com/bad6a860-8ea4-11ea-90d0-222d5e1f3e65" style="width:100%;border:0; height:400px;" noborder border="0" />

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>